import React from "react"
import Layout from "../components/layout"
import TreiImg from "../images/treinamento.jpeg"

const Treinamento = ({data}) => {

    return (
    <Layout>
        <div>

            <div className="flex justify-between flex-wrap both-edges">
                <div className="w-1/2 pr-8 text-right">
                    <div className="w-auto  ">
                        <img className="w-full" src={TreiImg} alt="treinamento"/>
                    </div>                    
                    <section className="mt-4">
                        <p style={{ fontSize:'26px', marginBottom: '0px !important' }}><b>PARA MAIS INFORMAÇÕES</b><br/>contato@msiforks.com.br<br/><span style={{ fontSize:'36px' }}>tel: 11 5694-1000</span><br/><a style={{ fontSize:'18px', color: 'rgb(0, 0, 238)' }}href="https://www.facebook.com/media/set/?set=a.703736329684832.1073741830.149912475067223&type=3">Clique aqui - Próximos treinamentos</a></p>
                        
                    </section>
                </div>
                <div className="w-1/2 pl-8">
                    <section className="list-disc">
                        <p style={{ fontSize:'18px' }}>TREINAMENTO DE INSPEÇÃO E SEGURANCA DE GARFOS, NORMA ISO 5057</p>
                        <p>O treinamento é voltado para empresas de diversos segmentos, indústrias, locadores de máquinas, distribuidores de peças, operadores logísticos e empresas interessadas em melhorar as práticas internas de segurança e capacitação de seus funcionários.</p>
                        <p>O conteúdo do curso aborda os principais pontos técnicos de fabricação, modelos, segurança e inspeção de garfos. Durante o treinamento são verificadas as dúvidas sobre o correto uso dos garfos, procedimentos internos de segurança e possíveis melhorias. O treinamento pode ser efetuado na sede da MSI-Forks em São Paulo ou in-house (customizado conforme a necessidade de cada cliente).</p>
                        <p><b>Objetivo do treinamento:</b></p>
                        <ul>
                            <li style={{ marginBottom: '8px' }}>Conscientizar os participantes sobre práticas de uso, inspeção e segurança de garfos;</li>
                            <li style={{ marginBottom: '8px' }}>Revisar e criar rotina interna de inspeção de garfos;</li>
                            <li style={{ marginBottom: '8px' }}>Analisar operações críticas de movimentação e oferecer sugestões para redução de risco;</li>
                            <li style={{ marginBottom: '8px' }}>Aumentar a segurança e produtividade da operação.</li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    </Layout>    
    )
}

export default Treinamento